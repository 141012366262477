.publications__info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  font-size: 14px;
}


.publication {
  margin-bottom: 25px;
}

.publication__title {
  font-weight: 500;
}

.publication__link {
  width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.publication__hr {
  margin: 14px 0;
  background-color: rgb(243, 243, 243);
  height: 1px;
  border: 0;
}

@media only screen and (max-device-width: 768px) {

  .publication__link {
    width: 100%;
  }
}