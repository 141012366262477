.card-link {

}

.card-link .container:hover {
  -webkit-box-shadow: 1px 0px 20px -3px rgba(34, 60, 80, 0.27);
  -moz-box-shadow: 1px 0px 20px -3px rgba(34, 60, 80, 0.27);
  box-shadow: 2px 0px 20px -9px rgba(34, 60, 80, 0.27);
}

.card-link > * {
  cursor: pointer;
}

.card-link__content {
  margin-bottom: 20px;
}

.card-link__link {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 14px;
}