@import '/src/index.scss';

.bel-it-jobs-status {
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @extend .container-shadow
}

.bel-it-jobs-status__header {

  font-weight: bold;
  box-sizing: border-box;
}

.bel-it-jobs-status__period-container {
  display: flex;
  align-items: baseline;
}

.bel-it-jobs-status__period {
  font-weight: normal;
  font-size: 12px;
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.bel-it-jobs-status__content {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.bel-it-jobs-status-new__left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.bel-it-jobs-status-new__right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 110px;
}

.bel-it-jobs-status__date {
  font-weight: normal;
  font-size: 12px;
  color: gray;
}

.bel-it-jobs-status__controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}


@media only screen and (max-device-width: 768px) {

  .bel-it-jobs-status-items-container {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .bel-it-jobs-status__inner-container {
    padding: 0;
  }

  .bel-it-jobs-status-new__right {
    font-size: 90px;
  }

  .bel-it-jobs-status__header h2 {
    margin-bottom: 5px;
  }

}