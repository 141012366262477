.multiple-topics-line-chart {}

.multiple-topics-line-chart__tooltip-wrapper {
  font-size: 14px;
  background-color: white;
  color: rgb(102, 102, 102);
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 12px -2px rgba(34, 60, 80, 0.15);
  -moz-box-shadow: 0px 0px 12px -2px rgba(34, 60, 80, 0.15);
  box-shadow: 0px 0px 12px -2px rgba(34, 60, 80, 0.15);
}