@import '/src/index.scss';

.configurable-table-container {
  background-color: var(--color-background-container);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  @extend .container-shadow
}

.configurable-table-container__full {
  min-height: 750px;
}

.configurable-table-container__top5 {
  min-height: 400px;
}

.configurable-table__title {
  margin-bottom: 10px;
}

.configurable-table__link {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
  font-size: 14px;
}

@media only screen and (max-device-width: 768px) {

  .configurable-table-container {
    padding: 20px 10px;
    font-size: 14px;
  }

  .configurable-table-container__remove-first>* .simple-table__th:first-child {
    display: none;
  }

  .configurable-table-container__remove-first>* .simple-table__td:first-child {
    display: none;
  }
}