.two-in-one-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 5px;
}

.two-in-one-wrapper__one {
  flex: 1;
}

.two-in-one-wrapper__two {
  flex: 1;
}




@media only screen and (max-device-width: 768px) { 
  .two-in-one-wrapper {
    display: block;
  }

}