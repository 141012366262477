.devpulse__tooltip {
  width: 300px;
  background-color: white !important;
  color: black !important;
  opacity: 1 !important;
  font-size: 14px !important;
  border-radius: 10px;
  font-weight: normal !important;
  /* -webkit-box-shadow: 1px 0px 10px -6px rgba(34, 60, 80, 0.27);
  -moz-box-shadow: 1px 0px 10px -6px rgba(34, 60, 80, 0.27);
  box-shadow: 1px 0px 10px -6px rgba(34, 60, 80, 0.27); */
  -webkit-box-shadow: 0px 0px 22px -2px rgba(34, 60, 80, 0.21);
  -moz-box-shadow: 0px 0px 22px -2px rgba(34, 60, 80, 0.21);
  box-shadow: 0px 0px 22px -2px rgba(34, 60, 80, 0.21);
  pointer-events: auto !important;
 }

.devpulse__tooltip:hover {
  /* visibility: visible !important; */
}

@media only screen and (max-device-width: 768px) { 
  .devpulse__tooltip {
    width: 200px;
  }

}