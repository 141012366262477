.bel-it-jobs-status-navbar-mobile {
  display: none;
  padding: 10px 15px;
  border-top: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid rgb(238, 238, 238);
  background-color: white;
  color: gray;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.bel-it-status-navbar__text {
  color: black;
}


@media only screen and (max-device-width: 768px) { 

  .bel-it-jobs-status-navbar-mobile {
      display: block;
      margin-bottom: 15px;
  }

  
}
