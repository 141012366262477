h3 {
  margin-bottom: 5px;
}

p {
  margin-top: 5px;
}

.main-page-intro {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.main-page-intro__left {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .main-page-intro__h1 {
  line-height: 35px;
} */

.main-page-intro__right {
  flex: 2;
}

.main-page-intro__right-image {
  width: 90%;
}



@media only screen and (max-device-width: 768px) { 
  .main-page-intro {
    flex-direction: column;
    padding: 0 20px;
  }

  .main-page-intro__right {
    display: none;
  }
  

}