.devpulse__login-outer-сontainer {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.devpulse__login-inner-сontainer {
  width: 400px;
}

.devpulse__login__register-text {
  font-size: 0.9rem;
}

.devpulse__login__button {
  width: 100%;
}

@media only screen and (max-device-width: 768px) {

  .devpulse__login-outer-сontainer {
    padding-top: 40px;
  }

  .devpulse__login-inner-сontainer {
    width: 90%;
  }

}