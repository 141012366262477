@import '/src/index.scss';

.topic-chart-container {
  background-color: var(--color-background-container);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  @extend .container-shadow
}

.topic-chart-container__link {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
  font-size: 12px;
}