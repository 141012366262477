.profile-icon__container {
  padding: 5px;
  background-color: var(--navbar-link-background-color);
  color: var(--navbar-link-color);
}

.profile-icon__container:hover {
  background-color: var(--navbar-link-background-color-hover);
  color: var(--navbar-link-color-hover);
}

.profile-icon__icon>svg {
  color: white;
  margin-right: 7px;
}

.profile-icon__username {
  cursor: pointer;
}