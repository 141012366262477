.fact {
  padding: 70px 50px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fact-title {
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  line-height: 90%;
  margin-bottom: 20px;
  color: var(--color-button);
}

.fact-description {
  text-align: center;
}

.fact__lear-more-link {
  padding-top: 7px;
}

@media only screen and (max-device-width: 768px) {
  .fact {
    padding: 50px 40px !important;
  }

  .fact-title {
    font-size: 24px;
  }
}