.table-of-contents {

}

.table-of-contents__header {
  margin-bottom: 10px;
}

.table-of-contents__link {
  display: block;
  margin-bottom: 7px;
}