.keywords-container {
  font-size: 0.8rem;
  display: flex;
  flex-wrap: wrap;
}

.keywords-container__keyword {
  background-color: rgb(247, 247, 247);
  padding: 1px 5px;
  margin-right: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}