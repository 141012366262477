.horizontal-menu {

  width: 100%;
}

.menu-select {
  width: 180px;
}


@media only screen and (max-device-width: 768px) { 

  .menu-select {
    width: 180px;
  }
  
}
