.menu__show-mobile-menu {
  display: none;
}

.menu__show-desktop-menu {
  height: 100%;
}


@media only screen and (max-device-width: 768px) {

  .menu__show-desktop-menu {
    display: none;
  }

  .menu__show-mobile-menu {
    display: block;
  }

}