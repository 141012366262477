.companies-emmployees-table__title {
  display: flex;
  flex-direction: row;
}

.companies-emmployees-table__tooltip {
  margin-left: 5px; 
  color: rgb(145, 151, 163);
  width: 14px;
  height: 14px; 
}