.simple-table {
  border-spacing: 0;
  width: 100%;
}

.simple-table__tr {
  cursor: pointer;
}

.simple-table__tr:hover {
  background-color: rgb(247, 247, 247);
}

.simple-table__th {
  margin: 0;
  padding: 0.8rem 0.8rem 0.5rem 0.8rem;
}

.simple-table__td {
  margin: 0;
  padding: 0.8rem;
}

.simple-table__th {
  text-align: start;
  font-size: 14px;
  font-weight: 700;
  color: rgb(87, 87, 87) !important;
}

.simple-table__td {
  border-top: 1px solid rgb(243, 243, 243);
}

@media only screen and (max-device-width: 768px) { 

  .simple-table__th, .simple-table__td {
    margin: 0;
    padding: 0.6rem;
    padding: 0.7rem 0.6rem;
  }  
}
