.navbar {
  display: flex;
  justify-content: center;
  background-color: var(--navbar-and-footer-background-color);
  height: 80px;
  margin-bottom: 10px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
}

.navbar-container__logo-container {}

.navbar-container__logo {
  display: flex;
}

.navbar-container__logo-icon {
  vertical-align: text-top;
  width: 35px;
  margin-right: 5px;

}

.navbar-container__logo-text {
  color: var(--navbar-link-color-hover);
  font-size: 20px;
}

.navbar-container__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.navbar-container__menu>div {
  margin-right: 25px;
}

@media only screen and (max-device-width: 768px) {

  .navbar {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
    height: 60px;
  }

  .navbar-container__menu {
    flex-wrap: wrap;
  }

  .navbar-container__menu>div {
    margin-right: 0;
  }

}