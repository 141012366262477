* {
  box-sizing: border-box;
  // scroll-behavior: smooth;
}

body {
  --color-background: #f6f8fab4;
  --color-font: #2f3136;

  --navbar-link-background-color-hover: rgba(255, 255, 255, 0.06);
  --navbar-and-footer-background-color: #2a2b30;

  --navbar-link-color: rgb(223, 223, 223);
  --navbar-link-color-hover: rgb(255, 255, 255);

  --color-link: rgb(0, 0, 238);
  --color-link-hover: rgb(0, 0, 238);

  --color-button: rgb(53, 130, 230);
  --color-button-hover: rgb(38, 114, 214);
  --color-add-course-button: rgb(17, 173, 51);

  --color-background-container: white;

  --padding-container: 20px;

  background-color: var(--color-background);
  /* background-color: #f7f8fb; */
  color: var(--color-font);
  font-size: 16px;
  line-height: 1.4rem;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
  // font-family: 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  line-height: 2.2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.1rem;
}

a {
  color: var(--color-link);
  text-decoration-line: none;
  outline: none;
}

a:hover {
  color: var(--color-link-hover);
}

a:visited {
  color: var(--color-link);
}

.main-container {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.main-container-courses {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}


.container {
  background-color: var(--color-background-container);
  /* margin-top: 20px; */
  margin-bottom: 20px;
  padding: var(--padding-container);
  border-radius: 10px;
  @extend .container-shadow
}

.container-without-padding {
  background-color: var(--color-background-container);
  margin-bottom: 20px;
  border-radius: 10px;
  @extend .container-shadow
}

.container-shadow {
  -webkit-box-shadow: 1px 0px 10px -4px rgba(34, 60, 80, 0.27);
  -moz-box-shadow: 1px 0px 10px -4px rgba(34, 60, 80, 0.27);
  box-shadow: 1px 0px 10px -4px rgba(34, 60, 80, 0.27);
}

.header-margin {
  margin-left: 15px;
}

.header-top-margin {
  margin-top: 15px;
}

.devpulse-button {
  padding: 5px;
  background-color: var(--color-button);
  color: white;
  border: 1px solid var(--color-button);
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.devpulse-selected {
  color: white;
  background-color: var(--color-button);
  border: 1px solid var(--color-button);
}

.devpulse-not-selected {
  background-color: white;
  color: var(--color-button);
  border: 1px solid var(--color-button);
}

.positive {
  color: rgb(37, 175, 67);
}

.negative {
  color: rgb(235, 0, 0);
}

.bold-text {
  font-weight: bold;
}

.capitalize-text {
  text-transform: capitalize;
}

.text-as-link {
  color: var(--color-link);
  cursor: pointer;
}

.devpulse-success {
  background-color: rgb(209, 231, 221);
  border: 1px solid rgb(186, 219, 204);
  border-radius: 7px;
  padding: 3px;
}

.devpulse-error {
  background-color: rgb(248, 215, 218);
  border: 1px solid rgb(245, 194, 199);
  border-radius: 7px;
  padding: 3px;
}

.devpulse-warning {
  background-color: rgb(255, 243, 205);
  border: 1px solid rgb(255, 236, 181);
  border-radius: 7px;
  padding: 3px;
}

.devpulse-pending {
  background-color: #cfe2ff;
  border: 1px solid #b6d4fe;
  border-radius: 7px;
  padding: 3px;
}

@media only screen and (max-device-width: 768px) {


  h1 {
    font-size: 1.6rem;
  }

  .main-container {
    width: 97%;
    margin-bottom: 50px;
  }

  .main-container-courses {
    width: 97%;
    /* margin-bottom: 50px; */
  }

  .container {
    width: 100%;
  }

  .header-top-margin {
    margin-top: 0;
  }

}