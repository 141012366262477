.menu-desktop {
  padding: 0;
  height: 100%;
  margin: 0 16px;
  text-indent: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.menu-desktop__link-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-desktop__link-container:hover {
  /* background-color: var(--navbar-link-background-color-hover); */
  /* color: var(--navbar-link-color-hover); */
}

.menu-desktop__link {
  display: block;
  color: var(--navbar-link-color);
  margin: 0 5px;
  padding: 4px 4px;
  cursor: pointer;
}

.menu-desktop__link:hover {
  color: var(--navbar-link-color-hover);
  /* background-color: rgb(227, 230, 232); */
  /* border-radius: 10px; */
}

.menu-desktop__link-container:hover .menu-desktop__link {
  color: var(--navbar-link-color-hover);
}