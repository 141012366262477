.bel-it-jobs-status-navbar-desktop {
  font-size: 14px;
  background-color: #393c41;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.bel-it-jobs-status-navbar-desktop:hover {
  background-color: var(--navbar-link-background-color-hover);
}

.bel-it-jobs-status-navbar-desktop__text {
  color: var(--navbar-link-color);
}


@media only screen and (max-device-width: 768px) { 

  .bel-it-jobs-status-navbar-desktop {
    display: none;
  }
  
}
