.ant-popover-inner-content {
  padding: 0 !important;
}


/* desktop menu */
.ant-menu {
  background: var(--navbar-and-footer-background-color) !important;
}

.ant-menu-item-selected {
  background: var(--navbar-and-footer-background-color) !important;
}

.ant-menu-item {
  padding: 0 3px !important;
}


.ant-menu-item:hover {
  background-color: var(--navbar-link-background-color-hover) !important;
  color: var(--navbar-link-color-hover) !important;
}

.ant-menu-submenu {
  padding: 0 3px !important;
}

.ant-menu-submenu-title {
  width: 100%;
  height: 100%;
}

.ant-menu-submenu-title:hover {
  background-color: var(--navbar-link-background-color-hover) !important;
  color: var(--navbar-link-color-hover) !important;
}