.add-course {
  padding: 15px;
  margin-top: 15px;
  min-height: 500px;
}

.add-course__title {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.add-course__form {}

.add-course__contact {
  display: flex;
  flex-direction: row;
}

.add-course__error {
  color: red
}

.add-course__add-button-container {
  text-align: center;
}

/*  ======================  */
.avatar-uploader>.ant-upload {
  width: 350px;
}


@media only screen and (max-device-width: 768px) {

  .add-course__add-button {
    width: 70%;
  }

}