.languages-pie-chart-container {}

.languages-pie-chart__content {
  display: flex;
}

.languages-pie-chart__chart {
  flex: 3;
  height: 380px;
  width: 100%;
  margin-right: 50px;
}

.languages-pie-chart__desc {
  flex: 2;
  padding-top: 20px;
}

@media only screen and (max-device-width: 768px) {

  .languages-pie-chart-container {
    padding: 10px !important;
  }

  .languages-pie-chart__content {
    flex-direction: column;
  }

  .languages-pie-chart__desc {
    padding-top: 0;
  }

}