.footer {
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  background: var(--navbar-and-footer-background-color);
  color: white;
}

.footer__container {
  width: 800px;
}

.footer__question {
  margin-bottom: 20px;
  font-size: 14px;
}

.footer__links {
  display: flex;
  justify-content: center;
}

.footer__link {
  color: white;
  padding-right: 50px;
}

.footer__link:visited,
.footer__link:hover {
  color: white;
}

.footer__link:last-child {
  padding-right: 0;
}


@media only screen and (max-device-width: 768px) {

  .footer {
    padding-left: 10px;
    padding-right: 10px;
  }
}