.page-updated {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(179, 179, 179);
  min-height: 22px;
}

@media only screen and (max-device-width: 768px) {

  .page-updated {
    margin-top: 0;
  }
}