.simple-line-chart-container {
  /* width: 50%; */
}

.simple-line-chart__header {
  margin-left: 5px;
}

.simple-line-chart__title {}

.simple-line-chart__title>h3 {
  margin-bottom: 10px;
}

.simple-line-chart__current-value-container {
  display: flex;
  flex-direction: row;
}

.simple-line-chart__current-value {
  font-size: 14px;
}

.simple-line-chart__current-value-info {
  margin-left: 5px;
  color: rgb(145, 151, 163);
  width: 14px;
  height: 14px;
}

.simple-line-chart__change-container {
  font-size: 14px;
}

.simple-line-chart__change {
  font-weight: bold;
}

.simple-line-chart {
  height: 300px;
}

.pick-period-container {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: center;
}

.pick-period-item {
  padding: 5px;
  margin-right: 15px;
  font-size: 12px;
  background-color: white;
  border: 1px solid var(--color-button);
  color: var(--color-button);
  border-radius: 10px;
  cursor: pointer;
}

.pick-period-item-selected {
  background-color: var(--color-button);
  color: white;
}

.simple-line-chart__tooltip {
  font-size: 14px;
  background-color: white;
  color: rgb(102, 102, 102);
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 12px -2px rgba(34, 60, 80, 0.15);
  -moz-box-shadow: 0px 0px 12px -2px rgba(34, 60, 80, 0.15);
  box-shadow: 0px 0px 12px -2px rgba(34, 60, 80, 0.15);
}


@media only screen and (max-device-width: 768px) {
  .simple-line-chart {
    height: 200px;
  }

}