.course {
  padding: 15px;
  margin-top: 15px;
  min-height: 300px;
  /* font-size: 14px; */
}

.course__breadcrumb-container {
  padding: 8px 0;
}

.course__breadcrumb,
.course__breadcrumb:visited {

  font-size: 14px;
  color: rgb(146, 146, 146);
}

.course__title {
  line-height: 1.6em;
  margin-bottom: 20px;
}

.course__header {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.course__author {
  font-weight: 600;
  align-items: center;
  color: var(--color-button);
  cursor: pointer;
}

.course__only_for_owner_text {
  color: rgb(248, 89, 89);
  font-weight: bold;
}

.course__controls {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.course__header-margin {
  margin-bottom: 15px;
}

.course__visit-site-button {
  padding: 7px;
  background-color: var(--color-button);
  color: white;
  border: 1px solid var(--color-button);
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.course__visit-site-button:hover,
.course__visit-site-button:visited {
  background-color: var(--color-button-hover);
  color: white;
  border: 1px solid var(--color-button-hover);
}

/* .course__author-image {
  display: flex;
  justify-content: center;
  width: 80px;
  margin-right: 15px;
  background-color: black;
} */

.course__description {
  margin-bottom: 20px;
}


.course__info {
  margin-top: 30px;
  font-size: 12px;
  color: gray;
}

.course__contact-item {
  margin-bottom: 7px;
  color: var(--color-button)
}

.course__contact-phone {}

.course__contact-email {}

.course__contact-link {}


@media only screen and (max-device-width: 768px) {

  .course__breadcrumb-container {
    padding: 0;
  }

}